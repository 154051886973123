// margin
.margin-16 {
  margin: 16px;
}

.margin-20 {
  margin: 20px;
}

.margin-top-10 {
  margin-top: 10pxs;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.margin-top-bottom-16 {
  margin: 16px 0;
}

// padding
.padding-10 {
  padding: 10px;
}

.padding-16 {
  padding: 16px;
}

.padding-left-20 {
  padding-left: 20px;
}
