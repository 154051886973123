@import 'theme.scss';
@import './common/margin-pagging.scss';

:root {
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.87);
  --mat-dialog-actions-padding: 20px;
  --mat-toolbar-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-sidenav-content-background-color: #e0e0e0;
  --mdc-list-list-item-label-text-size: 16px;
  --mat-form-field-container-vertical-padding: 0.75em;
  --mat-form-field-container-height: 30px;
  --mat-form-field-filled-with-label-container-padding-top: 18px;
  --mat-form-field-filled-with-label-container-padding-bottom: 6px;
  --mat-table-header-headline-color: #0000008a;
  --mat-table-header-headline-size: 12px;
  --mat-protected-button-icon-spacing: 0;
  --mat-filled-button-icon-offset: 0;
  --mdc-text-button-label-text-tracking: normal;
  --mat-table-row-item-label-text-line-height: 20px;
}

.debug-chip {
  margin-left: 20px;

  .mat-mdc-chip {
    background-color: #e0e0e0;
    color: black;
  }
}

.mat-toolbar {
  color: $whiteFont;

  &.main-header {
    z-index: 2;
    height: 65px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
      0 1px 3px 0 rgba(0, 0, 0, 0.12);

    &.testing {
      background-color: #bf360c !important;
      .mat-mdc-select-placeholder {
        &.ng-star-inserted {
          color: #f9683a !important;
        }
      }
    }

    &.development {
      background-color: #006064 !important;
      .mat-mdc-select-placeholder {
        &.ng-star-inserted {
          color: #428e92 !important;
        }
      }
    }

    &.production {
      background-color: #535d6a !important;
      .mat-mdc-select-placeholder {
        &.ng-star-inserted {
          color: #808a98 !important;
        }
      }
    }
  }
}

.common-filter-padding {
  padding: 20px;
}

.snackbar-alert {
  --mdc-snackbar-container-color: #e53935;
  --mat-mdc-snack-bar-button-color: #4f5d6a;
}

.snackbar-primary {
  --mdc-snackbar-container-color: #0064a7;
  --mat-mdc-snack-bar-button-color: white;
}

mat-form-field {
  width: 100%;
}

.mat-mdc-badge-small .mat-mdc-badge-content {
  width: 8px;
  height: 8px;
  line-height: 8px;
  right: 0 !important;
  top: 0 !important;
}

.elementContainer {
  margin-bottom: 20px;
}

ac-mandator-selector {
  .mobile-mandator-selector {
    width: 200px;
    font-size: 16px;
  }

  min-width: 0px;
  max-width: none;
  margin-right: 25px;
}

ac-trade-type-selector {
  .mobile-trade-type-selector {
    width: 200px;
    font-size: 16px;
  }

  min-width: 0px;
  max-width: none;
  margin-right: 25px;
}

.main-side-nav-container {
  background-color: #e0e0e0;
  flex: 1;

  .main-side-nav {
    min-width: 300px !important;
    padding-right: 20px;
    padding-left: 20px;

    h4 {
      margin: 0 0 0 16px;
      opacity: 0.6;
    }

    mat-icon {
      margin-right: 16px;
      opacity: 0.6;
    }
  }

  hr {
    opacity: 0.2;
  }

  mat-cell {
    .table-actions {
      cursor: pointer;
    }
  }

  .small-font-table-cell {
    font-size: 12px;
  }
}

.mat-list-card {
  .mat-mdc-card-content {
    background-color: #e0e0e0;
  }

  .overview-table-with-filter {
    background-color: white;
  }
}

.sub-mat-toolbar.mat-toolbar {
  .mat-mdc-badge-content {
    color: transparent;
  }

  background-color: #f5f5f5;
  z-index: 2;
  height: 65px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);

  color: black;

  span.divider {
    margin: 0 10px 0 10px;
  }

  .sub-main-nav {
    height: 100%;
    padding: 0 auto;
  }

  .active-view-button {
    color: rgb(0, 100, 167) !important;
  }

  a:link,
  a:visited,
  a:hover,
  a:active {
    color: black;
    text-decoration: none;
  }

  .filter-toggle-icon {
    cursor: pointer;
    margin: 5px 0 0 20px;
    -moz-transition: all 0.25s ease-out;
    -webkit-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    // transition-delay: 0.5s;
  }

  .filter-toggle-icon.turned {
    -ms-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

h1.application-title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 8px;
}

img.application-logo {
  width: 120px;
  margin: 0 20px 0 20px;
}

.main-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.clickableElement {
  cursor: pointer;
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

.list-style-none {
  list-style: none;
}

.mat-mdc-option {
  white-space: initial !important;
}

.mat-mdc-select-panel .mat-mdc-optgroup-label,
.mat-mdc-select-panel .mat-mdc-option {
  line-height: 1rem !important;
}
.mat-mdc-select-panel {
  max-width: 320px !important;
}

.padding-10 {
  padding: 10px;
}

.table-cell-bold-text-pointer {
  font-weight: 900;
  cursor: pointer;
}

//DateTimePicker
.pc-dateTimePicker .mat-mdc-form-field-underline {
  display: none;
}

.mat-mdc-datepicker-dialog .mat-mdc-dialog-container {
  min-width: 300px !important;
}

.deactivated {
  background-color: #eeeeee;
}

.deactivated-color {
  color: #90a4ae;
}
