@use '@angular/material' as mat;

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

$pc-blue-grey: (
  50: #eceff1,
  100: #cfd8dc,
  200: #b0bec5,
  300: #90a4ae,
  400: #78909c,
  500: #607d8b,
  600: #546e7a,
  700: #4f5d6a,
  800: #4f5d6a,
  900: #263238,
  A100: #cfd8dc,
  A200: #b0bec5,
  A400: #78909c,
  A700: #455a64,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: white,
    500: white,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: white,
    A700: $light-primary-text,
  ),
);

$pc-blue: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: #2196f3,
  600: #1e88e5,
  700: #0064a7,
  800: #1565c0,
  900: #0d47a1,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: white,
    600: white,
    700: white,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: white,
    A400: white,
    A700: white,
  ),
);

$primary: mat.m2-define-palette($pc-blue, 700);
$accent: mat.m2-define-palette($pc-blue-grey, 700, A100, A400);

// The warn palette is optional (defaults to red).
$warn: mat.m2-define-palette(mat.$m2-red-palette, 600);

// Create the theme object (a Sass map containing all of the palettes).
$theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
    ),
    typography:
      mat.m2-define-typography-config(
        $font-family: 'Roboto, "Helvetica Neue", sans-serif',
        $body-1:
          mat.m2-define-typography-level(
            $font-size: 14px,
          ),
        $body-2:
          mat.m2-define-typography-level(
            $font-size: 14px,
          ),
      ),
    density: 0,
  )
);

$whiteFont: rgba(255, 255, 255, 0.87);

// Active icon color in list nav
md-nav-list {
  [md-list-item].active {
    md-icon[md-list-avatar] {
      background-color: mat.m2-get-color-from-palette($accent);
      color: mat.m2-get-color-from-palette($accent, default-contrast);
    }

    md-icon[md-list-icon] {
      color: mat.m2-get-color-from-palette($accent);
    }
  }
}

/* Fixes for material components */
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line,
.mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line,
.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 3rem !important;
}
.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 0 4px !important;
}
.mat-mdc-cell:first-of-type,
.mat-mdc-header-cell:first-of-type,
.mat-mdc-footer-cell:first-of-type {
  padding-left: 24px !important;
}
.mat-mdc-cell:last-of-type,
.mat-mdc-header-cell:last-of-type,
.mat-mdc-footer-cell:last-of-type {
  padding-right: 24px !important;
}
mat-toolbar {
  .mat-mdc-select,
  .mat-mdc-toolbar-row,
  .mat-mdc-select-arrow {
    color: inherit !important;
    font-weight: inherit !important;
  }
}
.mat-mdc-select-arrow-wrapper {
  height: 1rem !important;
}
.mat-mdc-button > .mat-icon,
.mat-mdc-unelevated-button > .mat-icon,
.mat-mdc-raised-button > .mat-icon,
.mat-mdc-outlined-button > .mat-icon {
  font-size: 1.5rem !important;
  height: 1.5rem !important;
  width: 1.5rem !important;
}
/* Fixes for material components */

/* ------------------------------------------------------------------------------- */
$foreground: map-get($theme, foreground);
$background: map-get($theme, background);

@include mat.core();
@include mat.all-component-themes($theme);
@include mat.typography-hierarchy($theme);

// Apply theme for this app

// NGX Charts
[ngx-charts-axis-label] text {
  fill: mat.m2-get-color-from-palette($foreground, secondary-text);
}

.tick text {
  fill: mat.m2-get-color-from-palette($foreground, disabled);
}

.gridline-path {
  &.gridline-path-horizontal,
  &.gridline-path-vertical {
    stroke: rgba(black, 0.06);
  }
}

.legend-title-text {
  color: mat.m2-get-color-from-palette($foreground, secondary-text);
}

ngx-charts-line-chart,
ngx-charts-area-chart,
ngx-charts-area-chart-stacked {
  .gridline-path {
    &.gridline-path-vertical {
      display: none;
    }
  }
}

ngx-charts-line-chart {
  .line-series {
    .line {
      stroke-width: 2;
    }
  }
}
